import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLlavero (Vue, filter, search, sorter, page) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('codigo', search)
        .addILike('ean', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.codigo.value) {
      apiFilter.addILike(filter.codigo.field, filter.codigo.value)
    }
    const resp = await Vue.$api.call('llavero.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLlaveroRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idllavero', pks)
    const resp = await Vue.$api.call('llavero.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
